// src/components/UserNotFound.js
import React from 'react';
import { Container, Typography, Box } from '@mui/material';

const UserNotFound = () => {
  return (
    <Container maxWidth="sm" sx={{ marginTop: 4 }}>
      <Box 
        sx={{ 
          textAlign: 'center',
          padding: 2,
          border: '1px solid',
          borderColor: 'error.main',
          borderRadius: 2,
          backgroundColor: 'background.paper'
        }}
      >
        <Typography variant="h4" color="error" gutterBottom>
          User Not Found
        </Typography>
        <Typography variant="body1">
          The user details could not be found. Please check the certificate ID and try again.
        </Typography>
      </Box>
    </Container>
  );
};

export default UserNotFound;
